import React, { Component } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import cx from "classnames"
import BrandStore from "stores/BrandStore"
import ChallengeStore from "apps/challenge/stores/ChallengeStore"
import Challenge from "apps/challenge/models/Challenge"
import Milestone from "apps/challenge/models/Milestone"
import checkCircle from "images/icons/check-circle.svg"
import CountUp from "react-countup"
import { easeInCubic } from "helpers/easings"
import AchievementProgressBar from "apps/dashboard/AchievementProgressBar"
import moment from "moment-timezone"

export interface Props {
  store: BrandStore
  challenge: Challenge
  completed: boolean
}

@observer
export default class ChallengeCard extends Component<Props> {
  challengesStore = new ChallengeStore(this.props.store)
  challenge = this.props.challenge
  completed = this.props.completed
  attendanceCount = this.challenge.attendanceCount
  milestones = this.challenge.milestones
  achieved = this.completed && this.challenge.achieved
  unachieved = this.completed && !this.challenge.achieved
  achievedMilestones = this.milestones.filter(
    milestone => this.attendanceCount >= milestone.goal
  )
  achievedMilestone = this.achievedMilestones.pop()
  unachievedBrandMilestones = (this.challenge.challengeType === "brand" && this.milestones.filter(
    milestone => this.attendanceCount < milestone.goal
  )) || []
  lowestUnachievedBrandMilestone = this.unachievedBrandMilestones.length ? this.unachievedBrandMilestones[0] : null
  currentBrandMilestone = this.achievedMilestone ?  this.achievedMilestone  : this.lowestUnachievedBrandMilestone
  displayedMilestone = !this.challenge.optedIn
    // first milestone is "zero" milestone - only use if not opted in
    ? this.challenge.milestones[0]
    : this.challenge.challengeType === "user"
      // user challenge will only have two milestones: zero and current
      ? this.challenge.milestones[1]
      : this.currentBrandMilestone && this.currentBrandMilestone[0] ? this.currentBrandMilestone[0] : this.currentBrandMilestone
  baseGoal = this.milestones[0].goal
  lastMilestone = this.challenge.milestones[this.challenge.milestones.length - 1]
  homeTimezone = this.props.store!.userStore!.homeLocation!.timezone || ''
  endDate = moment.tz(this.challenge.endDate.replace(' UTC', 'Z'), this.homeTimezone)
  defaultImage = this.challenge.challengeMilestoneDefaultImage
  labels = this.milestones.map((milestone) => {
    return milestone.goal
  })
  displayedDescription = !this.challenge.optedIn
    // Use default challenge description if not opted in
    ? this.challenge.description
    : this.optedInDescription
  track = this.props.store!.track

  @observable optInClicked: boolean = false

  get ends(): string {
    const endDate = moment.tz(this.challenge.endDate.replace(' UTC', 'Z'), this.homeTimezone)
    const today = moment.tz(this.homeTimezone)
    return endDate >= today ? 'Ends' : 'Ended'
  }

  get optedInDescription(): string {
    return this.challenge.challengeType === "brand"
      // brand challenges use current milestone
      ? Array.isArray(this.currentBrandMilestone) ? this.currentBrandMilestone[0]!.description : this.currentBrandMilestone!.description
      // user challenges return goal zero if segment challenge not completed
      : this.challenge.attendanceCount < this.challenge.milestones[1].goal
        ? this.challenge.milestones[0].description
        : this.challenge.milestones[1].description
  }

  formattedEventProps(challenge: Challenge) {
    return {
      join_date: moment().format('YYYY-MM-DD'),
      challenge_name: challenge.name,
      challenge_type: challenge.challengeType,
      end_date: moment(challenge.endDate).format('YYYY-MM-DD'),
    }
  }

  handleResponse(res: any) {
    const challenge = res.data.challenges[0]
    if (challenge && challenge.optedIn) {
      this.optInClicked = challenge.optedIn
      this.track.event("challenge_opt_in_success", {}, this.formattedEventProps(challenge))
    } else {
      this.handleError(challenge)
    }
  }

  handleError(challenge: Challenge) {
    this.track.event("challenge_opt_in_failure", {}, this.formattedEventProps(challenge))
  }

  handleJoinClick(challenge: Challenge) {
    const optInEvent = "challenge_tap opt in"
    switch (challenge.challengeType) {
      case "brand":
        this.track.event(optInEvent, {}, this.formattedEventProps(challenge))
        this.challengesStore.brandOptIn(challenge.id)
          .then(res => this.handleResponse(res))
          .catch(() => this.handleError(challenge))
        return
      case "user":
        this.track.event(optInEvent, {}, this.formattedEventProps(challenge))
        this.challengesStore.userOptIn()
          .then(res => this.handleResponse(res))
          .catch(() => this.handleError(challenge))
        return
      default:
        return
    }
  }

  render() {
    return (
      <div className={cx(
        "challengeCard",
        {
          "achieved": this.achieved,
          "unachieved": this.unachieved,
        }
      )}>
        <div className="challengeCard-top">
          <div className="challengeCard-top-title">
            { this.achieved && (
              <img src={checkCircle} alt="Checkmark icon" />
            )}
            { this.challenge.name }
          </div>
          {(this.challenge.actionUrl || this.challenge.learnMoreUrl) && (
            <a
              href={ this.challenge.actionUrl || this.challenge.learnMoreUrl }
              target="_blank"
              className="challengeCard-top-link">
                { this.challenge.actionUrlText || 'Learn More' }
            </a>
          )}
        </div>
        <div className="challengeCard-body">
          { this.milestones.length ? (
            <>
              <div className="challengeCard-body-milestone-title">{ this.displayedMilestone!.title }</div>
              <div className="challengeCard-body-description">{ this.displayedDescription }</div>
              {this.challenge.termsUrl && this.challenge.termsUrlText && (
                <a
                  className="challengeCard-body-terms"
                  href={this.challenge.termsUrl}
                  target="_blank"
                >
                  {this.challenge.termsUrlText}
                </a>
              )}
              <div className="challengeCard-body-wrap">
                <div className="challengeCard-body-wrap-left">
                  { ((this.achievedMilestone && this.achievedMilestone.prizeImage) || this.defaultImage) && (
                    <img
                      className="challengeCard-body-wrap-left-image"
                      src={
                        this.achievedMilestone && this.achievedMilestone!.goal !== this.baseGoal
                        ? this.achievedMilestone!.prizeImage
                        : this.defaultImage
                      }
                      alt="milestone"
                    />
                  )}
                  <span className="challengeCard-body-wrap-left-number">
                    <CountUp
                      end={this.attendanceCount > this.lastMilestone.goal
                        ? this.lastMilestone.goal
                        : this.attendanceCount
                      }
                      duration={1.25}
                      easingFn={easeInCubic}
                    />
                    /{ this.lastMilestone!.goal }</span>
                </div>
                { ((this.challenge.challengeType === "brand" || this.challenge.optedIn) && this.challenge.hideEndDate !== true) && (
                  <div className="challengeCard-body-wrap-right">
                    { this.ends } { this.endDate.format(this.props.store.styleClasses.ChallengeCard__dateFormat) }
                  </div>
                )}
              </div>
              <div className="challengeCard-bottom">
                { this.challenge.optedIn || this.optInClicked ? (
                  <AchievementProgressBar
                    store={this.props.store}
                    target={this.lastMilestone!.goal}
                    now={this.attendanceCount}
                    labels={this.labels}
                  />
                ) : (
                  <button
                    className='btn btn-primary w-100 challengeCard-bottom-button'
                    onClick={() => this.handleJoinClick(this.challenge)}
                  >
                    Join Challenge
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="challengeCard-body-description">
              Sorry, there are no milestones set for this challenge
            </div>
          )}
        </div>
      </div>
    )
  }
}
