import * as React from "react"
import cx from "classnames"

export interface CustomPillsProps<T extends string> {
  options: [T, string][]
  selected: T
  className?: string
  onChange(key: T): void
}

export default class CustomPills<T extends string> extends React.Component<
  CustomPillsProps<T>
> {
  handleChange = (key: T) => (e: React.MouseEvent) => {
    e.preventDefault()
    this.props.onChange(key)
  }

  public render() {
    const { options, selected, className } = this.props
    return (
      <ul
        className={cx(
          "nav",
          "nav-pills",
          "custom-pills",
          "nav-fill",
          "p-0",
          className
        )}
      >
        {options.map(([key, name]) => (
          <li
            className={cx("nav-item", {
              active: selected === key,
            })}
            key={key}
            role="tab"
            aria-controls={key}
            aria-selected={selected === key ? true : false}
            onClick={this.handleChange(key)}
          >
            <div>{name}</div>
          </li>
        ))}
      </ul>
    )
  }
}
