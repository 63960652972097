import * as React from "react"
import { RouteComponentProps } from "react-router"
import { observer } from "mobx-react"
import getIn from "lodash/get"
import LocationSummary from "models/LocationSummary"
import ServiceBookingStore from "apps/book/stores/ServiceBookingStore"
import { PlanInfo, GiftCardInfo } from "apps/buy/components/PaymentPage"
import PageTracker from "components/wrappers/PageTracker"
import { Link, Redirect } from "react-router-dom"
import BookAndConfirmInfoCard from "apps/book/components/BookAndConfirmInfoCard"

export interface Props extends RouteComponentProps<{ serviceTypeId?: string }> {
  locationSummary: LocationSummary
  serviceBookingStore: ServiceBookingStore
  planInfo: PlanInfo
  giftCardInfo?: GiftCardInfo
}

export default class ServiceBookAndConfirmSuccessPage extends React.Component<Props> {
  render() {
    const { locationSummary, serviceBookingStore } = this.props
    const {
      brandStore,
      scheduleEntrySummary,
      serviceType,
      error: bookingError,
    } = serviceBookingStore

    if (!serviceBookingStore.booking && !serviceBookingStore.error) {
      return <Redirect to="/bookings" />
    }

    const bookingErrorMessage = getIn(bookingError, 'message')
    const errorMessage = bookingError
      ? bookingErrorMessage === "Instructor is already booked"
        ? `Your purchase of 1 Credit was processed successfully, but it looks like someone else grabbed that session spot!\n
        Please join us for a different session with the credit purchased using the \'BOOK A SESSION\' button below.`
        : bookingErrorMessage
      : undefined

    const title = bookingError
      ? bookingErrorMessage
      : "Your Booking is Confirmed!"

    const subtitle = bookingError
      ? "Please try a different session using 'BOOK A SESSION' button below."
      : serviceType!.name

    const bookingCTA = bookingError
      ? <Link className="btn btn-primary w-100" to={`/book/${locationSummary.id}/services`}>
        {/* TODO: We have to set brand-specific "Session" copy dynamically */}
          Book A Session
        </Link>
      : <Link className="btn btn-primary w-100 xpass-schedule-btn" to={`/bookings`}>
          View My Schedule
        </Link>

    // NOTE: the UI below is very very similar to the BookingSuccessPage
    // That component has some class-specific logic still, but the UI itself could be shared
    return (
      <div>
        <BookAndConfirmInfoCard
          title={title}
          scheduleEntry={scheduleEntrySummary}
          subtitle={subtitle}
          locationSummary={locationSummary}
          brand={brandStore.brand}
          copy={brandStore.copy}
          email={brandStore.userStore.session!.email}
          planInfo={this.props.planInfo}
          giftCardInfo={this.props.giftCardInfo}
          errorMessage={errorMessage}
          styleClasses={brandStore.styleClasses}
          settings={brandStore.settings}
        >
          <PageTracker name="service booking > success" />
          <div className="mt-4 text-center">
            {bookingCTA}
          </div>
          <div className="confirmed-links">
            {process.env.FEATURE_MVP_ONLY !== "1" && (
              <div className="confirm-link">
                <a href="#">Add to Calendar</a>
              </div>
            )}
            <div className="confirm-link">
              <a href={locationSummary.directionsUrl} target="_blank">
                Get Directions
              </a>
            </div>
            {locationSummary.email && (
              <div className="confirm-link">
                <a href={`mailto:${locationSummary.email}`}>Contact Location</a>
              </div>
            )}
          </div>
        </BookAndConfirmInfoCard>
      </div>
    )
  }
}
