import * as React from "react"
import * as cx from "classnames"
import { observer } from "mobx-react"
import { action, observable } from "mobx"
import PlanStore from "apps/buy/stores/PlanStore"
import { AxiosResponse, AxiosError } from "axios"
import { isApiError } from "helpers/errorHandling"
import PromoCodeStore from "apps/buy/stores/PromoCodeStore"

export interface Props {
  planStore: PlanStore
}

@observer
export default class PromoCodeForm extends React.Component<Props, {}> {
  promoCodeStore = new PromoCodeStore(this.props.planStore)

  // this is why we have formik oh well
  // refactor to formik if this gets any more complex
  @observable value: string = ""
  @observable isDirty: boolean = false

  componentWillUnmount() {
    this.promoCodeStore.dispose()
  }

  @action.bound
  handlePromoCodeChange(e: InputEvent) {
    this.isDirty = true
    this.value = e.currentTarget.value
  }

  handlePromoCodeSubmit = (e: FormEvent) => {
    e.preventDefault()
    this.isDirty = false
    this.promoCodeStore.addCode(this.value)
  }

  handlePromoCodeRemove = (e: ButtonEvent) => {
    e.preventDefault()
    this.value = ""
    this.isDirty = false
    this.promoCodeStore.removeCode()
  }

  @action.bound
  handlePromoCodeSuccess() {
    this.props.planStore.plan = this.promoCodeStore.plan
    this.props.planStore.promoCode = this.promoCodeStore.promoCode
  }

  @action.bound
  handlePromoCodeError(ex: AxiosError) {
    if (isApiError(ex.response) && ex.response.data.code === "invalid_code") {
      console.error(ex.response)
    } else {
      throw ex
    }
  }

  public render() {
    const { planStore } = this.props
    const status = this.promoCodeStore.status
    let errorMsg = this.promoCodeStore.errorMessage
    const isLoading = status === "loading"
    const isInvalid = status === "error" && !this.isDirty

    const inputClass = cx("form-control", {
      "is-valid": !isLoading && planStore.promoCode,
      "is-invalid": status === "error" && !this.isDirty,
    })

    // gross, but going to be miserable to QA w/o this
    // fine to remove at some point after this is launched
    // if you're reading this and this project is launched, congratulations!
    // also, sorry!
    const isTestbrand = planStore.brandStore.brandId === "testbrand"

    if (planStore.promoCode) {
      return (
        <Wrapper
          text="remove"
          disabled={isLoading}
          onClick={this.handlePromoCodeRemove}
          isInvalid={isInvalid}
          errorMsg={errorMsg}
        >
          <input
            type="text"
            className={inputClass}
            value={isLoading ? "" : planStore.promoCode}
            disabled
          />
        </Wrapper>
      )
    }

    return (
      <form onSubmit={this.handlePromoCodeSubmit}>
        <Wrapper 
          text="Apply" 
          disabled={isLoading || !this.value} 
          isInvalid={isInvalid}
          errorMsg={errorMsg}
        >
          <input
            type="text"
            className={inputClass}
            placeholder={isTestbrand ? "Gift Code (try promo100)" : "Promo"}
            value={this.value}
            aria-describedby={isInvalid ? "promo-error" : undefined}
            onChange={this.handlePromoCodeChange}
            disabled={isLoading || !!planStore.promoCode}
          />
        </Wrapper>
      </form>
    )
  }
}

interface WrapperProps {
  text: string
  disabled?: boolean
  onClick?(e: ButtonEvent): void
  isInvalid?: boolean
  errorMsg?: string
}

const Wrapper: React.SFC<WrapperProps> = ({
  children,
  text,
  disabled,
  onClick,
  isInvalid,
  errorMsg
}) => (
  <div>
    <div className="input-group my-4">
      {children}
      <div className="input-group-append">
        <button
          className="btn btn-secondary"
          disabled={disabled}
          onClick={onClick}
        >
          {text}
        </button>
      </div>
      {isInvalid &&
        <small id="promo-error" className="text-left mt-2 text-danger w-100">
          {errorMsg}
        </small>
      }
    </div>
  </div>
)
