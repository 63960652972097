import * as React from "react"

import BrandStore from "stores/BrandStore"
import devicePromoImage from "images/plus/3-device-mashup-promo_v2.png"

import aktIcon from "images/brand_icons/icon_akt.svg"
import bftIcon from "images/brand_icons/icon_bft.svg"
import clubpilatesIcon from "images/brand_icons/icon_clubpilates.svg"
import cyclebarIcon from "images/brand_icons/icon_cyclebar.svg"
import purebarreIcon from "images/brand_icons/icon_purebarre.svg"
import rowhouseIcon from "images/brand_icons/icon_rowhouse.svg"
import rumbleIcon from "images/brand_icons/icon_rumble.svg"
import stretchlabIcon from "images/brand_icons/icon_stretchlab.svg"
import strideIcon from "images/brand_icons/icon_stride.svg"
import yogasixIcon from "images/brand_icons/icon_yogasix.svg"
import { Link } from "react-router-dom"

const brandIcons = {
  akt: aktIcon,
  bft: bftIcon,
  clubpilates: clubpilatesIcon,
  cyclebar: cyclebarIcon,
  purebarre: purebarreIcon,
  rowhouse: rowhouseIcon,
  rumble: rumbleIcon,
  stretchlab: stretchlabIcon,
  stride: strideIcon,
  yogasix: yogasixIcon,
}

interface Props {
  store: BrandStore
}

const PlusPage: React.FunctionComponent<Props> = ({ store }) => {
  const {
    locStore: { currentLocation },
  } = store

  const { xplusLink, xplusShowBanner } = window.globals

  return (
    <div className="plusContainer">
      {xplusShowBanner && (
        <div className="alert alert-primary row align-items-center mt-2 mb-5 ml-1 mr-1">
          <>
            <div className="col-md-9">
            GO is now X+. To enjoy unlimited workout videos at home today, purchase an All Access subscription now. 
            </div>
            <div className="col-md-3 text-md-right primaryButton-container">
              <Link
                className="btn btn-primary primaryButton"
                to={{ pathname: `${xplusLink}${currentLocation!.clubreadyId}` }}
                target="_blank"
              >
                Start Your Trial
              </Link>
            </div>
          </>
        </div>
      )}

      <div className="contentContainer">
        <div className="header p-4">
          <h1>GO is now Xponential+</h1>
          <p>
            With more ways to train than ever before, sign up or claim your
            account in less than 5 minutes.
          </p>
        </div>
        <div className="content">
          <div className="text">
            <h2>10 Brands. One App.<br />Thousands of Classes</h2>
            <ul className="pb-4">
              <li>Live Daily Classes</li>
              <li>24/7 On Demand Workouts</li>
              <li>Access to Over 2,000+ Studios </li>
              <li>Available on Desktop, Tablet and Mobile Devices</li>
            </ul>
            <div className="primaryButton-container">
              <Link
                className="btn btn-primary primaryButton mb-4"
                to={{ pathname: `${xplusLink}${currentLocation!.clubreadyId}` }}
                target="_blank"
              >
                Start Your Free Trial
              </Link>
            </div>
            <div className="brand-card-icons">
              {Object.entries(brandIcons).map(([index, val]) => (
                <img key={index} src={val} alt={`${index} Icon`}/>
              ))}
            </div>
          </div>
          <div className="devicesPromo">
            <img src={devicePromoImage} alt="3-device-mashup-promo" />
          </div>
        </div>
        <div className="header pt-5">
          <h1>Existing GO Subscribers</h1>
        </div>
        <div className="content content_half">
          <div className="content_half-side">
            <div className="text">
              <h3 className="text-center mb-3">Activate your Subscription</h3>
              <p className="text-center mb-4">We will transfer your GO to XPLUS during the account creation process.</p>
              <ol>
                <li>Create an Account using the same email address as your GO account.</li>
                <li>During account creation, link your account to your home studio.</li>
                <li>Check the Subscriptions section for your subscription. It may take 30 minutes after the account is created to activate your GO subscription in XPLUS.</li>
              </ol>
            </div>
          </div>
          <div className="content_half-side">
            <div className="text">
              <h3 className="text-center mb-3">Apple and Google App Store Subscribers</h3>
              <p className="text-center mb-4">You will need to take a few extra steps after you create your account.</p>
              <ol>
                <li className="black">Open the brand member app that has your GO subscription (e.g. Pure Barre)</li>
                <li className="black">Select the GO tab, then select Current GO Subscriber, and select the Activate XPLUS Subscription button to complete the set-up.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PlusPage
