import React, { Component } from "react"
import { inject, observer } from "mobx-react"
import { observable } from "mobx"
import Dropdown from "react-bootstrap/Dropdown"
import Svg from "components/Svg"
import * as cx from "classnames"

import eatBsPrefix from "helpers/eatBsPrefix"

import BrandStore from "stores/BrandStore"
import { LIST_LENGTH } from "stores/LocationsStore"

export interface Props {
  store?: BrandStore
  className?: string
  disabled: boolean
}

// This is the location picker in the nav
@inject((store: BrandStore) => ({ store }))
@observer
export default class LocationPicker extends Component<Props, {}> {
  @observable isDropdownOpen = false

  handleLocationSelect = (locationId: string) => () => {
    this.props.store!.locationPickerStore.handleSelect(locationId)
  }

  handleToggleDropdown = (isOpen: boolean) => {
    this.isDropdownOpen = isOpen
    if (isOpen) {
      this.props.store!.track.event("studio menu_open")
    }
  }

  locationLabel = (label: string) => (
    <span
      className={cx(
        "location-picker__toggle",
        "d-inline-block",
        "h4",
        "mb-0",
        "pl-3",
        "pr-0",
        "py-3",
        "text-link-color",
        this.props.className,
        {
          "text-muted": this.props.disabled,
        }
      )}
    >
      {label}
      <span className="sr-only"> is current location</span>
      {!this.props.disabled && (
        <Svg
          className="ml-2"
          name={this.isDropdownOpen ? "caret-up" : "caret-down"}
          size="16"
          svgTitle={this.isDropdownOpen ? "Close dropdown" : "Open dropdown"}
        />
      )}
    </span>
  )

  placeholder = (label: string) => (
    <div className="location-picker w-100">{this.locationLabel(label)}</div>
  )

  componentDidUpdate = () => {
    const locationDropdownContainer = document.getElementById("location-picker")

    if(locationDropdownContainer) {
      if(locationDropdownContainer.hasAttribute("aria-haspopup")) {
        locationDropdownContainer.removeAttribute("aria-haspopup")
      }
      locationDropdownContainer.setAttribute("tabindex", "0")
    }
  }

  public render() {
    if (this.props.store!.isXponential) return null

    const pickerStore = this.props.store!.locationPickerStore

    if (!pickerStore.anyLocations) {
      return this.placeholder("Loading...")
    }

    const currentLocation = pickerStore.currentLocation!
    if (this.props.disabled) {
      return this.placeholder(currentLocation.name)
    }

    return (
      <Dropdown
        className={cx("location-picker", "w-100")}
        onToggle={this.handleToggleDropdown}
      >
        <Dropdown.Toggle
          id="location-picker"
          as={eatBsPrefix("div")}
          role="button"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              e.currentTarget.click(); // Simulate click event
            }
          }}
        >
          {this.locationLabel(currentLocation.name)}
        </Dropdown.Toggle>

        {/* {totalNumOfLocations > 0 && ( */}
        <Dropdown.Menu
          className={cx("location-picker__menu", "rounded-0", "m-0", "p-0")}
        >
          {pickerStore.menuLocations
            .slice(0, LIST_LENGTH)
            .map(({ id, name }) => (
              <Dropdown.Item
                key={`locationPicker:${id}`}
                className="d-flex justify-content-between align-items-center"
                onSelect={this.handleLocationSelect(id)}
              >
                {name}
                {this.props.store!.userStore.session &&
                  this.props.store!.userStore.session.locationId === id && (
                    <Svg className="ml-1 text-primary" name="home" size="16" />
                  )}
              </Dropdown.Item>
            ))}

          <Dropdown.Item onSelect={this.handleLocationSelect("all")}>
            <div className="d-flex justify-content-between align-items-center">
              View More
              <Svg className="ml-1" name="caret-right" size="12" />
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
        {/* )} */}
      </Dropdown>
    )
  }
}
