import React, { Component } from "react"
import { Link, Switch, Router, Route, Redirect } from "react-router-dom"

import BrandStore from "stores/BrandStore"

import LocationRoute from "components/wrappers/LocationRoute"
import RestrictedRoute from "components/wrappers/RestrictedRoute"
import ModuleLoader from "components/wrappers/ModuleLoader"
import RestrictedLocationRoute from "components/wrappers/RestrictedLocationRoute"

import DashboardPage from "apps/dashboard/Dashboard"
import AuthController from "apps/auth/components/AuthController"
import PurchaseFlowController from "apps/buy/components/PurchaseFlowController"
import PurchaseFlowV2Controller from "apps/buy/components/PurchaseFlowV2Controller"
import PurchaseFlowV3Controller from "apps/buy/components/PurchaseFlowV3Controller"

import AccountController from "apps/account/components/AccountController"
import ChallengeController from "apps/challenge/ChallengeController"
import BookingFlowController from "apps/book/components/BookingFlowController"
import MyBookingsController from "apps/bookings/components/MyBookingsController"
import OfferController from "apps/buy/components/OfferController"
import Error404Page from "apps/Error404Page"
import OfferSlotController from "apps/buy/components/OfferSlotController"
import BlockerPage from "components/BlockerPage"
import RedeemOfferPage from "apps/xpass/RedeemOfferPage"
import XpassMarketingPage from "components/XpassMarketingPage"
import PurchaseSuccess from "components/PurchaseSuccess"
import BrandPurchaseSuccess from "components/BrandPurchaseSuccess"
import BrandPurchaseError from "components/BrandPurchaseError"
import XpassAppLP from "apps/xpass/XpassAppLP"

import { LocalStorageKey, setLocal } from "utils/LocalStorage"
import PlusPage from "apps/plus/PlusPage"
import cx from "classnames"
import MyRewardsPage from "apps/xpass/rewards/components/MyRewardsPage"
import RewardDetails from "apps/xpass/rewards/components/RewardDetails"
import PurchasedTokensDetails from "apps/xpass/rewards/components/PurchasedTokensDetails"
import ClassPointsController from "apps/classpoints/components/ClassPointsController"
import IndigoInterstitialPage from "apps/indigo/components/IndigoInterstitialPage"
import { observer } from "mobx-react"
import PurchaseFlowSPCController from "apps/buy/components/xpass/PurchaseFlowSPCController"

export interface ContainedRoutesProps {
  store: BrandStore
  eywRedirect: boolean
  brandRedirect: boolean
  subBrand?: string
  brandLocationId?: string
}
@observer
export default class ContainedRoutes extends Component<
  ContainedRoutesProps,
  {}
> {
  setTFID() {
    const urlParams = new URLSearchParams(window.location.search)
    const TFID = urlParams.get("TFID")
    if (TFID) {
      setLocal("TFID" as LocalStorageKey, TFID)
    }
  }
  render() {
    const isSingleCheckout = location.pathname.includes("/buy/xponential-xpass/checkout")
    const isXpassLP = location.pathname.includes("/xpassapp")
    const isClassPoints = location.pathname.includes("/classpoints")

    const isMemberPlatformLive = this.props.store.userStore.isMemberPlatformLive

    return (
      <main>
        <div
          className={cx({
            "mx-3 py-4": isSingleCheckout,
            "container pb-5 pt-xl-4": !isSingleCheckout && !isXpassLP,
            "container__has-secondary": !this.props.store.isXponential && !isXpassLP,
            "pt-3": this.props.store.isXponential && !isSingleCheckout,
            "classpoints__container": isClassPoints
          })}>

          <Switch>
            <RestrictedRoute
              publicOnly
              path="/auth"
              roadblockPath={
                !this.props.store.isXponential ? "/covid-waiver" : ""
              }
              render={props => <AuthController {...props} />}
            />

            {/*
            See Restricted Route for the logic that pushes people here
            when they need to sign the waiver
            */}
            {!this.props.store.isXponential && (
              <RestrictedLocationRoute
                locationInPath
                path="/covid-waiver"
                render={props => (
                  <BlockerPage
                    brandStore={this.props.store}
                    eywRedirect={this.props.eywRedirect}
                    type="covidWaiver"
                    {...props}
                  />
                )}
              />
            )}

            <RestrictedLocationRoute
              locationInPath
              path="/la-fitness-disclaimer"
              render={props => (
                <BlockerPage
                  brandStore={this.props.store}
                  type="laFitnessAuth"
                  {...props}
                />
              )}
            />

            <RestrictedLocationRoute
              path="/indigo"
              render={props => <IndigoInterstitialPage {...props} />}
            />

            {isMemberPlatformLive && !this.props.store.isXponential && <Redirect to="/indigo" />}

            <RestrictedLocationRoute
              path="/book"
              locationInPath
              ignoreStoredLocation
              render={props => <BookingFlowController subBrand={this.props.subBrand} {...props} />}
            />

            {/*
            Xpass route for newly registered users to redeem free class package offer
            */}
            <RestrictedRoute
              path="/redeem-offer"
              render={props => (
                <RedeemOfferPage brandStore={this.props.store} {...props} />
              )}
            />

            {window.globals.xpassPhase3Disabled ?
              <Redirect path="/xpass" to="/" exact /> :
              <RestrictedLocationRoute
                path="/xpass"
                render={props => <XpassMarketingPage brandStore={this.props.store} {...props} />}
              />
            }

            <Route
              exact
              path="/offers/slot/:offerSlot"
              render={props => {
                this.setTFID()
                return <OfferSlotController {...props} />
              }}
            />

            <RestrictedLocationRoute
              path="/plus"
              render={props => <PlusPage store={this.props.store} />}
            />

            <LocationRoute
              path="/offers/slot/:offerSlot"
              locationInPath
              inComingSoon
              render={props => {
                this.setTFID()
                return <OfferController {...props} />
              }}
            />

            <LocationRoute
              path="/offers"
              locationInPath
              inComingSoon
              render={props => <OfferController {...props} />}
            />

            <RestrictedLocationRoute
              exact
              path="/"
              inComingSoon
              render={props =>
                <DashboardPage
                  eywRedirect={this.props.eywRedirect}
                  subBrand={this.props.subBrand}
                  brandLocationId={this.props.brandLocationId}
                  brandRedirect={this.props.brandRedirect}
                  {...props}
                />
              }
            />

            <Redirect path="/vod" to="/plus" exact />
            <Redirect path="/vod/packages" to="/plus" exact />
            <Redirect path="/video" to="/plus" exact />

            <Route
              path="/buy/xponential-xpass/checkout"
              render={props => <PurchaseFlowV2Controller {...props} />}
            />

            <Route
              path="/checkout"
              render={props => <PurchaseFlowV3Controller {...props} />}
            />

            {this.props.store.isXponential && (
              <Route
                path="/simple-checkout"
                render={props => <PurchaseFlowSPCController isSimpleCheckout {...props} />}
              />
            )}

            {!this.props.store.isXponential && (
              <Route
                path="/simple-checkout"
                render={props => <PurchaseFlowV3Controller isSimpleCheckout {...props} />}
              />
            )}

            <Route
              path="/xpassapp"
              render={props => <XpassAppLP />}
            />

            <RestrictedLocationRoute
              path="/buy"
              locationInPath
              inComingSoon
              render={props => <PurchaseFlowController {...props} />}
            />

            <RestrictedRoute
              path="/history"
              render={props => (
                <ModuleLoader
                  loadModule={() =>
                    import(
                      /* webpackChunkName: "HistoryController" */
                      "apps/history/components/HistoryController"
                    )
                  }
                  moduleProps={props}
                />
              )}
            />

            <RestrictedLocationRoute
              path="/bookings"
              render={props => <MyBookingsController {...props} />}
            />

            <RestrictedLocationRoute
              path="/classpoints"
              render={props => <ClassPointsController {...props} />}
            />

            <RestrictedRoute
              path="/rewards/tokens/purchased"
              render={props => <PurchasedTokensDetails {...props} />}
            />

            <RestrictedRoute
              path="/rewards/tokens/:rewardId"
              render={props => <RewardDetails {...props} />}
            />

            <RestrictedRoute
              path="/rewards/:rewardId"
              render={props => <RewardDetails isReward {...props} />}
            />

            <RestrictedRoute
              path="/rewards"
              render={props => <MyRewardsPage {...props} />}
            />

            <RestrictedRoute
              path="/account"
              render={props => <AccountController {...props} />}
            />

            <RestrictedRoute
              path="/challenges"
              render={props => <ChallengeController {...props} />}
            />

            <RestrictedRoute
              path="/xpass-checkout-success"
              render={props => <PurchaseSuccess />}
            />

            <LocationRoute
              path="/checkout-success"
              render={props => <BrandPurchaseSuccess />}
            />

            <Route
              path="/checkout-error"
              render={props => <BrandPurchaseError />}
            />

            {process.env.SHOW_STYLEGUIDE === "1" && (
              <RestrictedRoute
                path="/drydock"
                render={props => (
                  <ModuleLoader
                    loadModule={() =>
                      import(
                        /* webpackChunkName: "DrydockController" */
                        "apps/drydock/DrydockController"
                      )
                    }
                    moduleProps={props}
                  />
                )}
              />
            )}
            <Redirect path="/styleguide" to="/drydock" exact />
            {/* Error 404 */}
            <Route path="/error" component={Error404Page} />
            <Route component={Error404Page} />
        </Switch>
        </div>
      </main>
    )
  }
}