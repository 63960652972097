import * as React from "react"
import cx from "classnames"
import { observer } from "mobx-react"

import Room from "models/Room"
import RoomCell, { RoomCellSeat, RoomCellFeature } from "models/RoomCell"
import BrandStore from "stores/BrandStore"

interface Props {
  store?: BrandStore
  room: Room
  selectedSeat?: string
  selectedSeatLabel?: string
  onSelect(seatId: string, seatLabel?: string): void
}

const RoomMapPanel: React.FunctionComponent<Props> = ({ room, onSelect, selectedSeat, store }) => {
  return (
    <div className="room-map-panel">
      <div className="sr-only">This table represents the position of the bikes as related to the instructor</div>
      <table className="room-map">
        <tbody>
          {room.cells.map((rowCells, row) => (
            <tr key={row}>
              {rowCells.map((cell, col) => (
                <TableCell
                  store={store}
                  key={col}
                  spot={cell}
                  selectedSeat={selectedSeat}
                  onSelect={onSelect}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const TableCell: React.FunctionComponent<{
  store?: BrandStore
  spot: RoomCell
  selectedSeat?: string
  onSelect(seatId: string, seatLabel?: string): void
}> = ({ spot, onSelect: onSelectSeat, selectedSeat, store }) => {

  const seatLabel = (label: string | undefined) => {
    if (!label) return ""

    if (label.includes("F")) return "floor"

    if (label.includes("T")) return "tread"

    if (label.includes("B")) return "boxing"

    return ""
  }

  const className = cx("spot", `spot-${spot.type}`, seatLabel(spot.seatLabel))

  switch (spot.type) {
    case "seat":
      return (
        <SeatCell
          className={className}
          selectedSeat={selectedSeat}
          spot={spot}
          onSelectSeat={onSelectSeat}
        />
      )
    case "feature":
      return <FeatureCell spot={spot} className={className} />
    case "instructor":
      return <td className={className}>{store!.copy.instructor}</td>
    default:
      return <td className={className} />
  }
}

interface SeatCellProps {
  selectedSeat?: string
  spot: RoomCellSeat
  className: string
  onSelectSeat(seatId: string, seatLabel?: string): void
}

// TODO: need them to be able to see their own booking here
const SeatCell = observer(
  ({ spot, selectedSeat, onSelectSeat, className }: SeatCellProps) => {
    const selected = spot.seatId === selectedSeat
    const selectable = !spot.reserved
    const taken = !selectable && !selected
    return (
      <td
        className={cx(className, {
          [`spot-seat-taken`]: taken,
          [`spot-seat-selectable`]: selectable,
          [`spot-seat-selected`]: selected,
        })}
        aria-current={selected ? "true" : undefined}
        role="button"
        tabIndex={selectable ? 0 : -1} // Focusable only if selectable
        onClick={selectable ? () => onSelectSeat(spot.seatId, spot.seatLabel) : undefined}
        onKeyDown={selectable ? (e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onSelectSeat(spot.seatId, spot.seatLabel);
          }
        } : undefined}
        aria-disabled={!selectable} // Indicate when not selectable
      >
        <span>{spot.seatLabel || spot.seatId}</span>
      </td>
    )
  }
)

interface FeatureCellProps {
  spot: RoomCellFeature
  className: string
}
const FeatureCell: React.FunctionComponent<FeatureCellProps> = ({ spot, className }) => (
  <td className={cx(className, `spot-feature-${spot.feature}`)} />
)

export default observer(RoomMapPanel)
