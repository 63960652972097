import React, { useState } from "react"
import cx from "classnames"
import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import EarnStore from "apps/classpoints/stores/EarnStore"
import CheckmarkIcon from "images/xpass/icons/checkmark-green.svg"

export interface Props {
  store: BrandStore
  openPointsDetailModal: Function
}

const WaysToEarnCard = ({store, openPointsDetailModal}: Props) => {
  const [earnStore, setEarnStore] = useState(new EarnStore(store))

  return (
    <div className={cx("card", "ways-to-earn-card")}>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="text-dark">
          Points
        </h5>
      </div>
      <APILoader
        apiStore={earnStore}
        elementSize="element"
        render={() => {
          const { pointValues} = earnStore

          return (
            <div className="card-body d-flex flex-column p-0">
              {!!pointValues.length && (
                <ul className="list-group list-group-flush flex-grow-1 mb-0 rows-in pt-2">
                  {pointValues.map(entry => (
                    <li
                      key={entry.id}
                      className="list-group-item list-group-item__clickable mx-3 px-0 d-flex justify-content-between align-items-center"
                      onClick={() => openPointsDetailModal(entry)}
                      role="button"
                    >
                      <div className={cx("d-flex align-items-center")}>
                        <div className="list-group-item__image">
                          <img src={entry.thumbnailImageUrl} />
                        </div>
                        <div className="pl-3">
                          <div className="font-14">{entry.shortDescription}</div>
                          <div className="font-16 color-primary">{entry.rewardsText}</div>
                        </div>
                      </div>
                      {entry.earned && (
                          <img className="deal-card__checkmark-icon mx-2" src={CheckmarkIcon} alt="checkmark" />
                        )}
                    </li>
                  ))}
                </ul>
              )}
              {!pointValues.length && (
                <div className="list-group list-group-flush flex-grow-1 mb-0 rows-in py-4 mx-3">
                  No point events found.
                </div>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

export default WaysToEarnCard