import * as React from "react"
import * as moment from "moment"
import * as cx from "classnames"
import { observer, inject } from "mobx-react"
import { Link } from "react-router-dom"
import Duration from "helpers/Duration"
import Svg from "components/Svg"
import BrandStore from "stores/BrandStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
interface Props {
  className?: string
  compactLabels?: boolean
  hidePast?: boolean
  week: Duration
  store?: BrandStore
  scheduleEntryStore?: ScheduleEntryStore
}

const ScheduleWeekNav: React.FunctionComponent<Props> = ({
  week,
  compactLabels,
  hidePast,
  className,
  store,
  scheduleEntryStore,
}) => {
  const { startAt, prevDate, nextDate, through } = week
  const today = moment().format("YYYY-MM-DD")
  const linkClass = "text-decoration-none h4"
  const r = store!.routingStore

  let showFuture = true
  let showPast = true
  const { isXponential, styleClasses, copy } = store!

  if (isXponential && scheduleEntryStore) {
    const maxEndDate = moment(scheduleEntryStore!.maxEndDate, "YYYY-MM-DDTH:mm").format("YYYY-MM-DD")
    const endOfWeek = through.endOf("day").utc().format("YYYY-MM-DD")
    if (moment(maxEndDate).isSame(endOfWeek) || moment(endOfWeek).isAfter(maxEndDate)) {
      showFuture = false
    }

    const startDate = moment().format("YYYY-MM-DD")
    const startOfWeek = startAt.format("YYYY-MM-DD")
    if (moment(startDate).isSame(startOfWeek) || moment(startOfWeek).isBefore(startDate)) {
      showPast = false
    }
  }

  return (
    <div className={cx("schedule-navigation row flex-wrap", className)}>
      <div className="col m-0 order-2 order-lg-1">
        {week.date && week.date > today && (
          <Link
            to={r.mergeQuery({ date: today })}
            title="Today"
            className={linkClass}
          >
            <Svg className="mr-3" name="double-caret-left" size="16" svgTitle="Jump to today"/>
          </Link>
        )}
        {showPast && (!hidePast || (week.date && week.date > today)) && (
          <Link
            to={r.mergeQuery({ date: prevDate })}
            className={cx(linkClass, styleClasses.ScheduleWeekNav__link)}
          >
            <Svg className="mr-2" name="caret-left" size="16" svgTitle="Previous week"/>
            {copy.prevText} {!compactLabels && "Week"}
          </Link>
        )}
      </div>
      <div
        className={cx(
          "schedule-week-of",
          "col-12",
          "mb-3",
          "text-center",
          "order-1",
          "col-lg",
          "mb-lg-0",
          "order-lg-2"
        )}
      >
        {startAt.format(styleClasses.ScheduleWeekNav__dateFormat)} &ndash; {through.format(styleClasses.ScheduleWeekNav__dateFormat)}
      </div>

      <div className="col m-0 order-3 order-lg-3 text-right">
        {showFuture && (
          <>
            <Link
              to={r.mergeQuery({ date: nextDate })}
              className={cx(linkClass, styleClasses.ScheduleWeekNav__link)}
            >
              Next {!compactLabels && "Week"}
              <Svg className="ml-2" name="caret-right" size="16" svgTitle="Next week"/>
            </Link>

            
            {week.date && week.date < today && (
              <Link
                to={r.mergeQuery({ date: today })}
                title="Today"
                className={linkClass}
              >
                <Svg className="ml-3" name="double-caret-right" size="16" svgTitle="Today"/>
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default inject((store: BrandStore) => ({ store }))(
  observer(ScheduleWeekNav)
)
