import * as React from "react"
import { observer, inject } from "mobx-react"

import BrandStore from "stores/BrandStore"
import ChallengeStore from "apps/challenge/stores/ChallengeStore"
import LocationRoute from "components/wrappers/LocationRoute"
import APILoader from "components/wrappers/APILoader"
import { RouteComponentProps } from "react-router"
import CustomPills from "components/CustomPills"
import { observable, action } from "mobx"
import ChallengeCard from "./components/ChallengeCard"
import PageTracker from "components/wrappers/PageTracker"

export interface Props extends RouteComponentProps { store?: BrandStore }

@inject((store: BrandStore) => ({ store }))
@observer
export default class ChallengeController extends React.Component<Props, {}> {
  challengeStore = new ChallengeStore(this.props.store!)
  brandStore = this.props.store!
  challengeOptions: [string, string][] = [
    ["active", "Active Challenges"],
    ["completed", "Completed"]
  ]

  @observable showCompleted: boolean = false

  componentWillUnmount() {
    this.challengeStore.dispose()
  }

  shouldChange(challengeGroup: string) {
    return (challengeGroup === "completed" && !this.showCompleted) ||
      (challengeGroup === "active" && this.showCompleted)
  }

  @action.bound
  handleOptionChange(challengeGroup: string) {
    if (this.shouldChange(challengeGroup)) {
      this.showCompleted = !this.showCompleted
    }
  }

  get selectedOption(): string {
    return this.showCompleted ? this.challengeOptions[1][0] : this.challengeOptions[0][0]
  }

  public render() {
    return (
      <LocationRoute
        path={this.props.match.path}
        locationInPath={false}
        render={() => (
          <>
            <PageTracker name="challenges" />
            <div className="challengeContainer">
              {
                this.brandStore.brandId === 'bft' &&
                <div className="text-sm-center slide-in delay-1 mt-3 mb-5">
                  <h1>8 Week Challenge</h1>
                  <p>Reach your fitness goals by joining the 8 Week Challenge!</p>
                  <p>Offered three times a year, the 8WC includes: BFT's progressive programming, a sustainable nutrition plan,  free Evolt 360 scans, and coaching consultations to track your progress and keep you motivated!</p>
                  <p>Already joined? <a href="https://apps.apple.com/au/app/bft-performance/id1581543495" target="_blank">Download the BFT Performance App</a> to track your progress. Contact your studio or visit the website to <a href="https://www.bodyfittraining.com/challenges" target="_blank">learn more about the 8 Week Challenge.</a> </p>
                </div>
              }
              <h1 className="text-sm-center mb-0 slide-in delay-1 mt-3">{this.brandStore.brandId === 'bft' && 'Other'} Challenges</h1>
              <APILoader
                apiStore={this.challengeStore}
                elementSize="page"
                fetchProps={{ completed: this.showCompleted }}
                key={this.showCompleted ? '1' : '0'}
                alreadyLoaded={!this.shouldChange}
                render={() => {
                  const { challenges } = this.challengeStore

                  return (
                    <>
                      <div className="col-lg-5 pt-4 m-auto">
                        <CustomPills
                          options={this.challengeOptions}
                          selected={this.selectedOption}
                          onChange={this.handleOptionChange}
                          className="custom-pills-sm mb-0 text-uppercase"
                        />
                      </div>
                      <div className="challengeCardsContainer">
                        {challenges && challenges.map((challenge, index) => {
                          return (
                            <ChallengeCard
                              key={`challenge-${index}`}
                              store={this.props.store!}
                              challenge={challenge}
                              completed={this.showCompleted}
                            />
                          )
                        })}
                        {!challenges.length && this.showCompleted && (
                          <div className="mt-4">No completed challenges found.</div>
                        )}
                        {!challenges.length && !this.showCompleted && (
                          <div className="mt-4">No challenges currently available.</div>
                        )}
                      </div>
                    </>
                  )
                }}
              />
            </div>
          </>
        )}
      />
    )
  }
}
