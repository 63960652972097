import * as React from "react"
import { inject, observer } from "mobx-react"
import { observable, action } from "mobx"
import * as cx from "classnames"
import * as yup from "yup"
import { pick } from "lodash"

import { InjectedFormikProps, withFormik, Form, FormikBag } from "formik"
import { parse } from "helpers/queryString"
import { withAxiosErrorMessage } from "helpers/errorHandling"
import {
  phoneNumberValidator,
  nameValidator,
  passwordValidator,
} from "helpers/validations"

import PaymentSource from "models/PaymentSource"
import XpassV2FormRequest from "apps/auth/models/xpass/XpassV2FormRequest"

import BookingInfoStore from "apps/buy/stores/BookingInfoStore"
import BrandStore from "stores/BrandStore"
import PaymentSourceUpdateStore from "apps/account/stores/PaymentSourceUpdateStore"
import PaymentSourcesStore from "apps/account/stores/PaymentSourcesStore"
import PlanV2Store from "apps/buy/stores/PlanV2Store"
import PurchaseV2Store from "apps/buy/stores/PurchaseV2Store"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"

import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"
import APILoader from "components/wrappers/APILoader"
import PromoCodeV2Form from "apps/buy/components/PromoCodeV2Form"
import Spinner from "components/Spinner"
import PageTracker from "components/wrappers/PageTracker"
import ContractCheckbox from "apps/buy/components/ContractCheckbox"
import PackageRenewalText from "apps/buy/components/PackageRenewalText"

import logoUrl from "images/logos/xpass-logo-dark.svg"

export interface Props {
  packageId: string
  store?: BrandStore
  bookingInfoStore?: BookingInfoStore
  paymentSourcesStore?: PaymentSourcesStore
  paymentSource?: PaymentSource
}

export interface State {
  revealPassword: boolean
  disableRevealPassword: boolean
  stickyPlanEl: HTMLElement | null
}

type FormValues = XpassV2FormRequest

let query = parse(location.search)
const email = query.email ? decodeURI(query.email) : ''
let purchaseData: object = []
let planStore: PlanV2Store
let submitBuy: boolean

@inject((store: BrandStore) => ({ store }))
@observer
class InnerForm extends React.Component<
  InjectedFormikProps<Props, FormValues>, State
> {
  @observable contractChecked = false

  constructor(props: InjectedFormikProps<Props, FormValues>) {
    super(props)
    this.state = {
      revealPassword: false,
      disableRevealPassword: true,
      stickyPlanEl: null,
    }
  }

  planStore = new PlanV2Store(
    this.props.store!,
    this.props.packageId,
  )

  @action.bound
  handleTermsAccept(e: InputEvent) {
    this.contractChecked = e.currentTarget!.checked
  }

  componentDidMount() {
    this.props.store!.uiStore.hideNavLinks()
    this.props.store!.uiStore.lockLocationPicker()
    this.props.store!.styleClasses.MainMenu__container = 'light-nav'
    this.props.store!.styles.logoUrl = logoUrl
    this.props.store!.uiStore.nav.signOutLinkShow = false
    const bodyElement = document.querySelector('body') as HTMLElement
    this.setState({stickyPlanEl : document.querySelector('.xpassCheckout-callout')})
    bodyElement.classList.add("xpass-theme")
    window.addEventListener('scroll', this.handleOnScroll)
    window.addEventListener('resize', this.handleOnScroll)
  }

  componentWillUnmount() {
    this.props.store!.uiStore.showNavLinks()
    const bodyElement = document.querySelector("body") as HTMLElement
    bodyElement.classList.remove("xpass-theme")
  }

  handleOnScroll = (e: Event) => {
    this.setState({stickyPlanEl : document.querySelector('.xpassCheckout-callout')})
    const plansRect = this.state.stickyPlanEl!.getBoundingClientRect()
    const plansRectParent = this.state.stickyPlanEl!.parentElement!.getBoundingClientRect()
    const plansParentPadding = 24
    if ((plansRectParent!.top + plansParentPadding + plansRect!.height) < window.innerHeight) {
      this.state.stickyPlanEl!.classList.remove('sticky')
    } else {
      this.state.stickyPlanEl!.classList.add('sticky')
    }
  }

  revealPasswordToggle = () => {
    this.setState(prevState => ({ revealPassword: !prevState.revealPassword }));
  }

  handleRevealPasswordChange = () => {
    this.setState({ disableRevealPassword: this.props.values!.password!.length <= 0 })
  }

  formatCcNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.replace(/[^\d]/g, "")
    const ccNumberPartial = [
      value.slice(0, 4),
      value.slice(4, 8),
      value.slice(8, 12),
      value.slice(12, 16),
    ].filter(v => v)

    this.props.setFieldValue("ccNumber", ccNumberPartial.join(" "))
  }

  formatNumbersOnly = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.setFieldValue(
      e.currentTarget.name,
      e.currentTarget.value.replace(/[^\d]/g, "")
    )

  get isLoggedIn() {
    return isAuthorized(this.props.store!)
  }

  get pwToggleState() {
    let classes = "col-push-2 pwToggle"
    classes += this.state.revealPassword
      ? ' pwToggle__revealed'
      : ' pwToggle__hidden'
    if (this.state.disableRevealPassword) classes += ' pwToggle__disabled'
    return classes
  }

  get xpassPlansUrl() {
    return email ? `${window.globals.xpassPlansUrl}?email=${encodeURIComponent(email)}` : window.globals.xpassPlansUrl
  }

  handleBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    const fieldName = e.target.name
    const fieldValue = e.target.value
    const schema = getSchema(this.props)
    const password = this.props.values.password as string
    const phoneMain = this.props.values.phoneMain as string
    const ccNumber = this.props.values.ccNumber! as string
    const ccExpMonth = parseInt(this.props.values.ccExpMonth!, 10) as number
    const ccExpYear = parseInt(this.props.values.ccExpYear!, 10) as number
    const ccSecurityCode = parseInt(this.props.values.ccSecurityCode!, 10) as number

    this.props.setFieldValue(fieldName, fieldValue)
    this.props.setFieldError(fieldName, "")
    try {
      await schema.validateAt(fieldName, {...this.props.values, password, phoneMain, ccNumber, ccExpMonth, ccExpYear, ccSecurityCode})
      e.target.classList.add('is-valid')
    } catch(ex) {
      this.props.setFieldError(fieldName, ex.errors)
      e.target.classList.remove('is-valid')
    }
  }

  public render() {
    const { touched, errors, isSubmitting, isValid } = this.props
    const showTax = this.props.store!.settings.showTax

    const renderError = () => {
      return (
        <div className="alert alert-danger mx-auto">
          <h4>An Error Occurred</h4>
          <p>We apologize for the inconvenience</p>
          <a href={this.xpassPlansUrl}>Change Package</a>
        </div>
      )
    }

    // Ensure the purchase form submission isn't triggered by the promo code form
    const onSubmit = async () => {
      const errors = await this.props.validateForm()
      if (JSON.stringify(errors) === '{}') {
        submitBuy = true
        this.props.handleSubmit()
      }
    }

    return (
      <>
        <div className="mx-auto xpassCheckout">
          <div className="text-center xpassCheckout-head">
            {!this.isLoggedIn &&
              <div className="mb-1 xpassCheckout-text-muted xpassCheckout-text-medium">Step 2 of 2</div>
            }
            <h1 className="xpassCheckout-text-heading">Complete Your Purchase</h1>
          </div>
        </div>
        <Form
          noValidate
          id="XpassCheckoutForm"
          className="xpassCheckout my-4 mx-auto"
        >
        {!isSubmitting &&
          <>
            <PageTracker name={'xpass one-page checkout'} />
            <div className="xpassCheckout-column">
              <div className="row">
                <FormInput
                  label="Email"
                  name="email"
                  autoComplete="email"
                  className={cx("col-12 xpassCheckout-input", { 'hidden': this.isLoggedIn })}
                  maxLength={50}
                  disabled={(this.props.values.email && this.props.values.email === email) || isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Password"
                  name="password"
                  type={this.state.revealPassword ? 'input' : 'password'}
                  onKeyUp={this.handleRevealPasswordChange}
                  autoComplete="new-password"
                  className={cx("col-12 xpassCheckout-input", { 'hidden': this.isLoggedIn })}
                  maxLength={50}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                {!isSubmitting && !this.isLoggedIn &&
                  <button
                    type="button"
                    disabled={this.state.disableRevealPassword}
                    className={cx(this.pwToggleState)}
                    onClick={e => {
                      e.preventDefault()
                      this.revealPasswordToggle()
                    }}
                  >
                    <span className="sr-only">{this.state.revealPassword ? 'Hide' : 'Reveal'}</span>
                  </button>
                }
                <FormInput
                  label="Mobile Number"
                  name="phoneMain"
                  autoComplete="tel-national"
                  className={cx("col-12 xpassCheckout-input", { 'hidden': this.isLoggedIn })}
                  maxLength={50}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <div className={cx("col-pad mb-4 xpassCheckout-text-fine xpassCheckout-text-muted", { 'hidden': this.isLoggedIn })}>
                  This is required to book classes. We will not contact you via SMS for marketing purposes.
                </div>
                <div className="mb-1 col-pad xpassCheckout-text-subheading">
                  Set up your debit or credit card.
                </div>
                <FormInput
                  label="First Name"
                  name="firstName"
                  autoComplete="cc-given-name"
                  className="col-12 xpassCheckout-input"
                  maxLength={50}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Last Name"
                  name="lastName"
                  autoComplete="cc-family-name"
                  className="col-12 xpassCheckout-input"
                  maxLength={50}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Credit Card Number"
                  placeholder="1234 1234 1234 1234"
                  name="ccNumber"
                  autoComplete="cc-number"
                  className="col-12 col-lg-6 xpassCheckout-input"
                  onChange={this.formatCcNumber}
                  maxLength={20}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Expiration Month"
                  name="ccExpMonth"
                  autoComplete="cc-exp-month"
                  placeholder="MM"
                  className="col-6 col-lg-3 xpassCheckout-input"
                  onChange={this.formatNumbersOnly}
                  maxLength={2}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Expiration Year"
                  name="ccExpYear"
                  autoComplete="cc-exp-year"
                  placeholder="YYYY"
                  className="col-6 col-lg-3 xpassCheckout-input"
                  onChange={this.formatNumbersOnly}
                  maxLength={4}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Security Code"
                  name="ccSecurityCode"
                  autoComplete="cc-csc"
                  placeholder="CVV"
                  className="col-6 col-lg-3 xpassCheckout-input"
                  onChange={this.formatNumbersOnly}
                  maxLength={4}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Postal Code"
                  name="zip"
                  placeholder="12345"
                  autoComplete="billing postal-code"
                  className="col-6 col-lg-3 xpassCheckout-input"
                  maxLength={10}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
              </div>
            </div>
            <div className="xpassCheckout-column xpassCheckout-column__border">
              <APILoader
                apiStore={this.planStore}
                renderError={renderError}
                render={() => {
                  planStore = this.planStore
                  const pkg = planStore.package!
                  const { plan, promoCode, discount, originalPrice } = planStore
                  const usesPromo = promoCode && discount > 0
                  return (
                    <>
                      <div className="row xpassCheckout-callout sticky">
                        <div className="col-8 px-0">
                          <p className="mb-1 input-group">Your Selected Plan</p>
                          <h3 className="xpassCheckout-text-heading-sm">{pkg.name}</h3>
                          <p className="mb-0 xpassCheckout-text-fine">
                            <PackageRenewalText pkg={pkg} store={this.props.store!} />
                          </p>
                        </div>
                      </div>
                      <PromoCodeV2Form planStore={planStore} />
                      {plan!.payments
                        .filter(f => f.isDueToday)
                        .map((payment, i) => (
                          <div className="xpassCheckout-lineItem" key={i}>
                            <div className="text-left">Subtotal </div>
                            <div className="text-right">
                              {payment.subtotal.formatted}
                            </div>
                          </div>
                        ))
                      }
                      {showTax && (
                        <div className="xpassCheckout-lineItem">
                          <div className="text-left">Tax </div>
                          <div className="text-right">{plan!.todayTax.formatted} </div>
                        </div>
                      )}
                      <hr />
                      {usesPromo && (
                        <>
                          <div className="xpassCheckout-lineItem xpassCheckout-text-muted">
                            <div className="text-left">Original Total </div>
                            <div className="text-right">
                              {this.planStore.originalPrice!.formatted}
                            </div>
                          </div>
                          <div className="xpassCheckout-lineItem">
                            <div className="text-left text-success">
                              Discount Code {promoCode}
                            </div>
                            <div className="text-right text-success">
                              - ${discount.toFixed(2)}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="xpassCheckout-lineItem xpassCheckout-text-bold">
                        <div className="text-left ">Order Total </div>
                        <div className="text-right">{plan!.todayTotal.formatted}</div>
                      </div>
                      <hr />
                      {pkg.hasContract && pkg.contractUrl && (
                        <ContractCheckbox
                          pkg={pkg}
                          checked={this.contractChecked}
                          onChange={this.handleTermsAccept}
                          isSingleCheckout
                        />
                      )}
                      <FormButtons
                        submitText="Complete Purchase"
                        disabled={pkg.hasContract && !this.contractChecked || isSubmitting}
                        className="xpassCheckout-submit"
                        onSubmit={onSubmit}
                        isSingleCheckout={true}
                      />
                    </>
                  )
                }}
              />
            </div>
          </>
        }
        {isSubmitting &&
          <Spinner size={"page"} className="mx-auto" isSingleCheckout={true} />
        }
        </Form>
      </>
    )
  }
}

const schemaAuthorized = yup.object<FormValues>().shape({
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  phoneMain: phoneNumberValidator.label("Mobile Phone"),
  password: passwordValidator.label("Password"),
  ccNumber: yup
    .string()
    .label("Credit / Debit Card Number")
    .required(),
  ccSecurityCode: yup
    .number()
    .required()
    .label("Security Code"),
  ccExpMonth: yup
    .number()
    .required()
    .label("Expiration Month")
    .min(1)
    .max(12),
  ccExpYear: yup
    .number()
    .required()
    .label("Expiration Year")
    .min(new Date().getFullYear()),
  zip: yup
    .string()
    .required()
    .label("Postal Code"),
})

const schemaUnauthorized = yup.object<FormValues>().shape({
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  phoneMain: phoneNumberValidator.required().label("Mobile Phone"),
  password: passwordValidator.required().label("Password"),
  ccNumber: yup
    .string()
    .label("Credit / Debit Card Number")
    .required(),
  ccSecurityCode: yup
    .number()
    .required()
    .label("Security Code"),
  ccExpMonth: yup
    .number()
    .required()
    .label("Expiration Month")
    .min(1)
    .max(12),
  ccExpYear: yup
    .number()
    .required()
    .label("Expiration Year")
    .min(new Date().getFullYear()),
  zip: yup
    .string()
    .required()
    .label("Postal Code"),
})

const timeout = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/* Registers User and Signs In */
const registerUser = async (
  values: XpassV2FormRequest,
  formikBag: FormikBag<{ store?: BrandStore, userAuthStore?: UserAuthStore }, XpassV2FormRequest>,
): Promise<void> => {
  const { authStore, uiStore, locStore, track } = formikBag.props.store!
  const loc = locStore.currentLocation
  const type =  new XpassV2FormRequest()
  const accountValues = pick(values, [
    'email',
    'firstName',
    'lastName',
    'phoneMain',
    'zip',
    'countryCode',
    'password'
  ]);

  const handleSignInClick = () => {
    uiStore.closeModal()
    track.event("log in_tap sign in", { loc }, { cta: "sign in" })
  }

  try {
    const data = await authStore.register(Object.assign(type, accountValues))
    formikBag.setSubmitting(false)
    await timeout(1) // pause for 1ms to allow user sign up to happen
    track.event("authentication_success", { loc }, { cta: "complete purchase" })
    return Promise.resolve()
  } catch (err) {
    const response = withAxiosErrorMessage(err)
    formikBag.setSubmitting(false)
    formikBag.setErrors(err)
    track.event("authentication_failure", { loc }, { cta: "complete purchase" })

    if (response && response.data.code === "service_conflict") {
      uiStore.openModal({
        type: "error",
        title: "Something went wrong",
        message: response.data.message,
        modalShow: true,
        locationSummary: loc,
      })
    } else {
      // If there are any account creation issues, the user needs to be able to change the email address.
      // Set it to null and remove it from the query string
      values.email = ""
      formikBag.props.store!.routingStore.history.push(location.pathname)
      query = {}
      if (response && response.data.message.includes('User exists')) {
        uiStore.openModal({
          message: "XPASS User creation failed. User exists.",
          modalShow: true,
          children: (
            <div className="my-4 text-center">
              <div className="mb-3">An account already exists with this e-mail.</div>
              <a
                className="btn btn-primary mr-2"
                href="/auth/login"
                onClick={() => handleSignInClick()}
              >
                Sign In
              </a>
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={() => uiStore.closeModal()}
              >
                Close
              </button>
            </div>
          ),
        })
      } else {
        uiStore.openError(err)
      }
    }
    return Promise.reject()
  }

}

/* Submits payment method and saves it as default on user profile */
const savePaymentMethod = async (
  values: XpassV2FormRequest,
  formikBag: FormikBag<{ store?: BrandStore }, XpassV2FormRequest>,
): Promise<void> => {
  const purchaseStore = new PurchaseV2Store(planStore)
  const { store } = formikBag.props
  const paymentSourcesStore = new PaymentSourcesStore(store!)
  const paymentSourceUpdateStore = new PaymentSourceUpdateStore(
    paymentSourcesStore
  )

  store!.track.event("purchase_add new credit card")

  try {
    formikBag.setSubmitting(true)
    const { data } = await paymentSourceUpdateStore.update(values)
    formikBag.setSubmitting(false)
    if (data.errors) {
      const err = data.errors
      formikBag.setErrors(err)
      store!.track.event("add new credit card_failure")
      return Promise.reject()
    } else {
      store!.track.event("add new credit card_success")
      return Promise.resolve()
    }
  } catch (ex) {
    formikBag.setSubmitting(false)
    store!.track.event("add new credit card_failure")
    return Promise.reject()
  }
}

const createAvantScript = () => {
  const avantScript = document.createElement("script")
  avantScript.type = "text/javascript"
  avantScript.async = true
  avantScript.src = ("https:" == document.location.protocol ? "https://" : "http://") + "cdn.avmws.com/1023521/"
  document.head.appendChild(avantScript)
}

const fireAvantConversionPixel = (purchaseData: any) => {
  
    let amount = parseFloat(String(purchaseData.paymentAmount.raw || '0.01'))
    amount = isNaN(amount) ? 0.01 : amount
    const avantScript = document.createElement("script")
    avantScript.type = "text/javascript"
    avantScript.innerHTML = `\
      var _AvantMetrics = _AvantMetrics || [];\
      _AvantMetrics.push(['order',{ order_id:'${purchaseData.id}', amount:'${amount}', ecc:'${purchaseData.promoCode || ''}' }]);\
      _AvantMetrics.push(['item',{ order_id:'${purchaseData.id}', parent_sku:'${purchaseData.packageId}', price:'${amount}', qty:'1' }]);\
    `.replace(/  +/g, '')
    document.head.prepend(avantScript)
    // Fire this afterwards to trigger the conversion pixel that uses the array above
    setTimeout(() => { createAvantScript() }, 1000)
}

/* Submit purchase request (uses default payment method saved from savePaymentMethod) */
const purchase = async (
  formikBag: FormikBag<{ store?: BrandStore }, XpassV2FormRequest>,
) => {
  const purchaseStore = new PurchaseV2Store(planStore)
  const { store } = formikBag.props
  const { uiStore } = formikBag.props.store!
  const loc = store!.locStore.currentLocation

  formikBag.props.store!.track.event(
    "purchase_tap purchase",
    {
      loc: loc,
      pkg: purchaseStore.pkg,
      plan: purchaseStore.plan,
    },
    {
      purchasePromoCode: !!purchaseStore.promoCode,
    }
  )

  try {
    formikBag.setSubmitting(true)
    const res: any = await purchaseStore.makePurchase()
    formikBag.setSubmitting(false)
    const purchaseData = res.data.purchase
    store!.track.revenue(
      "purchase_success",
      {
        loc: loc!,
        pkg: purchaseStore.pkg,
        plan: purchaseStore.plan,
      },
      {
        clubreadyAgreementId: purchaseData.id,
        purchasePromoCode: !!purchaseStore.promoCode,
      }
    )
    purchaseData && fireAvantConversionPixel(purchaseData)
    return Promise.resolve()
  } catch (ex) {
    console.log(ex)
    if (ex.response.data.message === "Must be brand member") {
      uiStore.openModal({
        children: (
          <div>
            <p className="text-center">Looks like this email still needs to be associated with a Membership at one of our brands. Claim your account, or enter a code to continue.</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  uiStore.closeModal()
                  store!.routingStore.history.push('/account/claim')
                }}
              >
                Claim Accounts
              </button>
            </div>
          </div>
        ),
        modalShow: true,
      })
    }
    formikBag.setSubmitting(false)
    store!.track.event(
      "purchase_failure",
      {
        loc: loc,
        pkg: purchaseStore.pkg,
        plan: purchaseStore.plan,
      },
      {
        purchasePromoCode: !!purchaseStore.promoCode,
      }
    )
    return Promise.reject()
  }
}

const isAuthorized = (store: BrandStore) => {
  return store.userStore!.isLoggedIn
}

const getSchema = (props: Props) => {
  return isAuthorized(props.store!) ? schemaAuthorized : schemaUnauthorized
}

const PaymentV2Page = inject((store: BrandStore) => ({ store }))(
  observer(
    withFormik<Props & { store?: BrandStore }, XpassV2FormRequest>({
      mapPropsToValues: ({ store }) => {
        const user = store!.userStore.session!
        const isAuth = isAuthorized(store!)
        let emailValue = email

        // If user is logged in but an email is passed that does not match the logged in user,
        // log out that user and use the passed email instead for sign up.
        if (isAuth && email && email !== user.email) {
          store!.authStore.signOut("", false)
        } else if (isAuth && user.email) {
          emailValue = user.email
        }

        return {
          isDefault: true,
          email: emailValue || "",
          firstName: isAuth ? user.firstName as string : "",
          lastName: isAuth ? user.lastName as string : "",
          phoneMain: isAuth ? user.phoneMain as string : "",
          password: "",
          ccNumber: "",
          ccSecurityCode:  "",
          ccExpMonth: "",
          ccExpYear: "",
          zip: "",
          countryCode: store!.settings.billingCountryCode,
          type: ""
        }
      },
      validateOnChange: false,
      validateOnBlur: true,
      validationSchema: (props: Props) => { return getSchema(props) },
      handleSubmit: async (values: XpassV2FormRequest, formikBag: FormikBag<{ store?: BrandStore }, XpassV2FormRequest>) => {
        // Ensure this form submission isn't triggered by the promo code form
        if (submitBuy) {
          const store = formikBag.props.store!
          if (!isAuthorized(store)) {
            try { await registerUser(values, formikBag) }
              catch(ex) {
                console.log('spc registration error')
                throw ex
              }
          }
          try { await savePaymentMethod(values, formikBag) }
            catch(ex) {
              console.log('spc payment error')
              throw ex
            }
          try { await purchase(formikBag) }
            catch(ex) {
              console.log('spc purchase error')
              throw ex
            }
          if (purchaseData) store.routingStore.history.push('/xpass-checkout-success')
        } else {
          formikBag.setSubmitting(false)
        }
        submitBuy = false
      },
    })(InnerForm)
  )
)

export default PaymentV2Page
